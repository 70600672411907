import React, { useState, useEffect } from 'react';
import '../../styles/listing.scss';

export const ListingAmenities = ({ amenities, onToggleShow, showAmenityButton }) => {
    const [amenitiesDisplayed, setAmenitiesDisplayed] = useState(15);
    const displayText = amenities.length !== amenitiesDisplayed ? 'Show all' : 'Show less';

    const onToggleShowAll = (e) => {
        e.stopPropagation();
        
        if (amenitiesDisplayed !== 15) {
            setAmenitiesDisplayed(15);
        }
        else {
            setAmenitiesDisplayed(amenities.length);
        }
    }

    useEffect(() => {    
        if (onToggleShow) {
            onToggleShow();
        }
    }, [amenitiesDisplayed]);

    return (
        <div className="layout-column listing-amenities">
            <div className="listing-amenities__inclusions">
                <ul className="layout-row">
                    {
                        amenities.slice(0, amenitiesDisplayed).map((amenity, index) => {
                            return <li key={`amenity__${index}`} className="flex-3-cols">
                                <div className="listing-amenity__icon">
                                    { amenity.media && <img src={`/static${amenity.media.url}`} alt="icon" /> }
                                </div>
                                <div className="listing-amenity__name">{ amenity.amenityName }</div>
                            </li>
                        })
                    }
                </ul>
                {
                    amenities.length > 12 &&
                    <div className="text-center show-text">
                        { showAmenityButton && <span className="clickable" onClick={onToggleShowAll}>{displayText}</span> }
                    </div>
                }
            </div>
        </div>
    )
}

export default ListingAmenities;