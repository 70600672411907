import React, { Component } from 'react';
import Img from 'gatsby-image';

import ListingAmenities from '../Sections/ListingAmenities';
import ListingMap from '../Sections/ListingMap';

export class ListingTabs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedTabIndex: 0,
            screenSize: {
                width: 0,
                height: 0
            }
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
        this.onResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onResize = () => {
        const screenSize = {
            width: document.body.clientWidth,
            height: document.body.clientHeight
        };
        this.setState({ screenSize }, () => {
            this.resizeTabContent();
        });
    }

    onTabItemClick = (index, e) => {
        const { screenSize, selectedTabIndex } = this.state;

        if (screenSize.width <= 786) {
            if (index === selectedTabIndex) {
                this.setState({ selectedTabIndex: -1 }, () => {
                    let tabsContentContainer = document.getElementsByClassName('listing-tabs__contents');
                    
                    if (tabsContentContainer.length > 0) {
                        tabsContentContainer[0].style.height = '0px';
                    }
                });
                window.scrollTo(0, this.listingTabsRef.offsetTop);
            }
            else {
                this.setState({ selectedTabIndex: index }, () => {
                    let tabsContentContainer = document.getElementsByClassName('listing-tabs__contents');
                    let amenities = document.querySelectorAll('.listing-tabs__item__content .listing-amenities')[0];

                    if (tabsContentContainer.length > 0) {
                        if (index === 0) {
                            tabsContentContainer[0].style.height = amenities.clientHeight + 'px';
                        }
                        else {
                            tabsContentContainer[0].style.height = '0px';
                        }
                    }
                });
            }
        
            window.scrollTo(0, this.listingTabsRef.offsetTop * 2 - 200);
        }
        else {
            this.setState({ selectedTabIndex: index }, () => {
                let tabContainer = document.querySelector('.listing-tabs__contents');
                let activeTab = tabContainer.querySelector('.listing-tabs__item__content--active');
                tabContainer.style.height = activeTab.clientHeight + 'px';
            });
        }
    }

    resizeTabContent = () => {
        let amenities = document.querySelectorAll('.listing-tabs__item__content .listing-amenities')[0];
        let tabsContentContainer = document.getElementsByClassName('listing-tabs__contents');
        const { selectedTabIndex } = this.state;
        const { onChangeAmenityHeight } = this.props;

        if (selectedTabIndex === 0) {
            if (tabsContentContainer.length > 0) {
                tabsContentContainer[0].style.height = amenities.clientHeight + 'px';
                
                if (onChangeAmenityHeight) {
                    onChangeAmenityHeight();
                }
            }
        }
    }

    render() {
        const { amenities, address, floorPlan, showAmenityButton } = this.props;
        const { selectedTabIndex, screenSize } = this.state;

        let tabHeaders = [ 'Amenities', 'Location', 'Floor Plan'];

        let coords = [];
        coords.push({ lat: address.lat, lng: address.lng });
        
        return (
            <div className="listing-tabs__container">
                <div ref={(ref) => this.listingTabsRef = ref} className="listing-tabs__items">
                    {
                        tabHeaders.map((head, index) => {
                            return (
                                <div key={`listing-tab__${index}`} className={`listing-tabs__item ${selectedTabIndex === index ? 'listing-tabs__item--active' : ''}`} onClick={(e) => this.onTabItemClick(index, e)}>
                                    <div className="listing-tabs__item__title text-center">{head}</div>
                                    {
                                        screenSize.width <= 786 && index === 0 &&
                                        <div className="listing-tabs__contents">
                                            <div className={`listing-tabs__item__content ${selectedTabIndex === 0 ? 'listing-tabs__item__content--active' : ''}`}>
                                                <ListingAmenities amenities={amenities} onToggleShow={this.resizeTabContent} showAmenityButton={showAmenityButton} />
                                            </div>
                                        </div>
                                    }
                                    {
                                        screenSize.width <= 786 && index === 1 &&
                                        <div className="listing-tabs__contents">
                                            <div className={`listing-tabs__item__content ${selectedTabIndex === 1 ? 'listing-tabs__item__content--active' : ''}`}>
                                                <ListingMap coordinates={coords} zoom={16} />
                                            </div>
                                        </div>
                                    }
                                    {
                                        screenSize.width <= 786 && index === 2 &&
                                        <div className={`listing-tabs__contents ${floorPlan == null ? 'listing-tab__item_no-content' : ''} listing-tabs__floor-plan`}>
                                            <div className={`listing-tabs__item__content ${selectedTabIndex === 2 ? 'listing-tabs__item__content--active' : ''}`}>
                                                {
                                                    floorPlan != null ? <Img fluid={floorPlan.fluid} alt="floor plan" /> : "Floor plan coming soon."
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                {
                    screenSize.width > 786 &&
                    <div className="listing-tabs__contents">
                        <div className={`listing-tabs__item__content ${selectedTabIndex === 0 ? 'listing-tabs__item__content--active' : ''}`}>
                            <ListingAmenities amenities={amenities} onToggleShow={this.resizeTabContent} showAmenityButton={showAmenityButton} />
                        </div>
                        <div className={`listing-tabs__item__content ${selectedTabIndex === 1 ? 'listing-tabs__item__content--active' : ''}`}>
                            <ListingMap coordinates={coords} zoom={16} />
                        </div>
                        <div className={`listing-tabs__item__content ${selectedTabIndex === 2 ? 'listing-tabs__item__content--active' : ''} listing-tabs__floor-plan`}>
                            {
                                floorPlan != null ? <Img fluid={floorPlan.fluid} alt="Floor plan" /> : "Floor plan coming soon."
                            }
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default ListingTabs;
