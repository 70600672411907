import React, { Component } from 'react';
import { navigate, Link, graphql, StaticQuery } from 'gatsby';
import { ThemeProvider, ThemeContext } from '../context/ThemeProvider';
import Layout from '../components/layout';
import ListingTabs from '../components/Sections/ListingTabs';
import Carousel from '../components/Sections/Carousel';
import Content from '../components/Sections/Content';
import MediaBanner from '../components/Sections/MediaBanner';
import Footer from '../components/footer';
import MediaCarousel from '../components/Sections/MediaCarousel';

import iconBeds from '../images/ico-beds.svg';
import iconBathrooms from '../images/ico-bathrooms.svg';
import iconBedrooms from '../images/ico-bedrooms.svg';
import iconGuests from '../images/ico-guests.svg';
import { isVideo } from '../utilities/helpers';
import schemaGenerator from '../utilities/SchemaGenerator';
import listingService from '../services/ListingService';
import Axios from 'axios';

const CancelToken = Axios.CancelToken;
let source = CancelToken.source();

export class Listing extends Component {
    state = {
        scroll: {
            X: 0,
            Y: 0
        },
        scrollYIndex: 0,
        price: {},
        loadTabs: false
    }

    async componentDidMount() {
        const { content } = this.props.pageContext;
        window.addEventListener('scroll', this.onScroll);

        source.cancel();
        source = CancelToken.source();

        const priceResult = await listingService.getListingPrice({listingId: content.section.listingId },source.token);

        let banner = document.getElementsByClassName('listing-banner__header-content')[0];
        this.setState({ scrollYIndex: banner ? banner.clientHeight: 0, price: priceResult.data, loadTabs: true }, () => {
            // let headerBanner = document.getElementsByClassName('listing-banner__header-content')[0];
            // if (headerBanner) {
            //     headerBanner.style.visibility = 'visible';
            // }
            this.onScroll();
        });

        window.addEventListener('resize', this.carouselDescriptionAdjustment);
        this.carouselDescriptionAdjustment();

        if (content.section.airbnbReviewId) {
            setTimeout(() => {
                this.loadScript();
            }, 4000);
        }
        
        const blurredImageDiv = document.querySelectorAll(".blurred-img:not(.loaded)");
        
        blurredImageDiv.forEach((item) => {
            const img = item.querySelector("img");
            if (!img) return;
            function loaded() {
                item.classList.add("loaded")
            }

            if (img.complete) {
                loaded()
            } else {
                img.addEventListener("load", loaded)
            }
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.carouselDescriptionAdjustment);
        window.removeEventListener('scroll', this.onScroll);
        document.body.style.height = 'auto';
    }

    carouselDescriptionAdjustment = () => {
        // Set min-height of other carousel's description based on the maximum height of the carousel's description
        let carousels = document.querySelectorAll('.carousel-container');
        
        for (let c = 0; c < carousels.length; c++) {
            const descriptions = carousels[c].querySelectorAll('.carousel-item--content__description p');
            let height = 0;

            for (let d = 0; d < descriptions.length; d++) {
                if (descriptions[d].clientHeight > height) {
                    let carouselDescriptions = carousels[c].querySelectorAll('.carousel-item--content__description');
                    for (let index = 0; index < carouselDescriptions.length; index++) {
                        carouselDescriptions[index].style.minHeight = descriptions[d].clientHeight + "px";
                    }
                    height = descriptions[d].clientHeight;
                }
            }
        }
    }

    onScroll = () => {
        const scroll = {
            X: window.scrollX,
            Y: window.scrollY
        }
        this.setState({ scroll });

        // Compute and set body height
        this.computeBodyHeight();

        // Reduce opacity if banner content reaches header
        let bannerLocation = document.getElementsByClassName("listing-banner__location")[0];
        let bannerTitle = document.getElementsByClassName("listing-banner__title")[0];
        let bannerInclusions = document.getElementsByClassName("listing-banner__inclusions")[0];
        let bannerSummary = document.getElementsByClassName("listing-banner__summary")[0];

        this.reduceBannerOpacity(bannerLocation);
        this.reduceBannerOpacity(bannerTitle);
        this.reduceBannerOpacity(bannerInclusions);
        this.reduceBannerOpacity(bannerSummary);
    }

    computeBodyHeight = () => {
        if (typeof window === "undefined") return;
        
        if (document.getElementsByClassName('listing-main-container__scroll').length > 0) {
            let mainContainerHeight = document.getElementsByClassName('listing-main-container__scroll')[0].clientHeight;
            let searchBarHeight = document.getElementsByClassName('searchbar')[0].clientHeight;
            
            mainContainerHeight = mainContainerHeight + searchBarHeight;
            document.body.style.height = mainContainerHeight + 'px';
        }
        else {
            if (document.getElementsByClassName('listing-main-container').length > 0) {
                let mainContainerHeight = document.getElementsByClassName('listing-main-container')[0].clientHeight;
                let searchBarHeight = document.getElementsByClassName('searchbar')[0].clientHeight;
                
                mainContainerHeight = mainContainerHeight + searchBarHeight;
                document.body.style.height = mainContainerHeight + 'px';
            }
        }
    }

    reduceBannerOpacity(element) {
        if (element.getBoundingClientRect().top < 50) {
            element.style.opacity = 0.3;
        }
        else {
            element.style.opacity = 1;
        }
    }
    
    buildPageSections(content, sectionTemplate) {
        let pageSections = [];
        let customSections = content.section.customSections;

        if (sectionTemplate.length <= 0) {
            return pageSections;
        }

        const { mediaCollection } = this.props;

        if (customSections.length <= 0) return;

        customSections.map(customSection => {
            if (customSection.customSectionType === 'Carousel') {
                let medias = customSection.slides.map(x => {
                    let path = x.media.url.split('/');
                    return path[path.length - 1];
                });

                let mediaQueries = mediaCollection.filter(x => medias.includes(x.fluid.originalName));
                pageSections.push(<Carousel mediaQueries={mediaQueries} {...customSection} />);
            }
            else if (customSection.customSectionType === 'Content') {
                pageSections.push(<Content content={customSection.sectionContent} sectionIdentifier={customSection.sectionIdentifier} />);
            }
            else if (customSection.customSectionType === 'Media') {
                let isMediaVideo = isVideo(customSection.media.url);
                let mediaQuery = null;
                
                if (!isMediaVideo) {
                    let imageVariant = customSection.media.variant === "Full width" ? "" : "";
                    let path = customSection.media.url.split('/');
                    let imgName = `${imageVariant ? imageVariant + '_' : ''}${path[path.length - 1]}`;
                    mediaQuery = mediaCollection.find(x => x.fluid.originalName === imgName);
                }

                pageSections.push(<MediaBanner mediaQuery={mediaQuery} media={customSection.media} />);
            }
            else if (customSection.customSectionType === "MediaCarousel") {
                let medias = customSection.slides.map(x => {
                    if (x.imageItem == null) return '';
                    let path = x.imageItem.url.split('/');
                    return path[path.length - 1];
                });
                medias = medias.filter(x=> x !== '');
                let mediaQueries = mediaCollection.filter(x => medias.includes(x.fluid.originalName));

                // const MediaCarousel = loadable(() => import('../components/Sections/MediaCarousel'));
                pageSections.push(<MediaCarousel {...customSection} mediaQueries={mediaQueries} />);
            }

            return null;
        });

        return pageSections;
    }

    onSearchClick = async (data) => {
        const { content } = this.props.pageContext;
        const { checkin, checkout, guests } = data;
        const { price } = this.state;

        if (data.listingInfo.totalPrice > 0 && checkin && checkout && guests > 0) {
            source.cancel();
            source = CancelToken.source();
            const response = await listingService.validateDates({ listingId: content.section.listingId, checkInDate: checkin, checkOutDate: checkout }, source.token);
            if (!response.data) {
                alert('Please re-select your desired dates.');
                return;
            }

            try {
                var session = await listingService.createBookingStore({ listingId: content.section.listingId, checkIn: checkin, checkOut: checkout }, source.token);

                if (!session.hasError) {
                    navigate("/checkout/", {
                        state: {
                            ...data,
                            listingId: content.section.listingId,
                            bpid: content.section.bpid,
                            title: content.section.title,
                            propertyType: content.section.propertyType,
                            cleaningFee: price.cleaningFee,//content.section.prices[0].cleaningFee,
                            sessionId: session.data,
                            address: {
                                city: content.section.address[0].city,
                                country: content.section.address[0].country,
                                fullAddress: content.section.address[0].full
                            }
                        }
                    })
                }
                else {
                    alert('Unable to create a checkout session. Please try again later.');
                    return;
                }
            }
            catch {
                alert('Unable to create a checkout session. Please try again later.');
                return;
            }
        }
        else {
            if (guests <= 0) {
                alert('Please select the number of guests.');
                return;
            }
            else {
                alert('Please re-select your desired dates.');
                return;
            }
        }
    }

    onInquiryClick = (data) => {
        const { content } = this.props.pageContext;
        const {checkin, checkout, guests } = data;
        const { price } = this.state;

        if (data.listingInfo.totalPrice > 0 && checkin && checkout && guests > 0) {
            navigate("/inquiry/", {
                state: {
                    ...data,
                    listingId: content.section.listingId,
                    title: content.section.title,
                    propertyType: content.section.propertyType,
                    cleaningFee: price.cleaningFee, //content.section.prices[0].cleaningFee,
                    address: {
                        city: content.section.address[0].city,
                        country: content.section.address[0].country,
                        fullAddress: content.section.address[0].full
                    }
                }
            })
        }
        else {
            if (guests <= 0) {
                alert('Please select the number of guests.');
                return;
            }
            else {
                alert('Please re-select your desired dates.');
                return;
            }
        }
    }

    render() {
        const { layout, theme, content, seo, sectionTemplate, url } = this.props.pageContext;
        const { location, mediaCollection } = this.props;
        const pageSections = this.buildPageSections(content, sectionTemplate);
        const { scroll, scrollYIndex, price, loadTabs } = this.state;
        let isStickyHeader = false;
        let mainContainerStyle = {};
        let classNames = "";

        let checkinState, checkoutState, guestState;

        if (location.state) {
            checkinState = location.state.checkin;
            checkoutState = location.state.checkout;
            guestState = location.state.guests;
        }

        const searchBarSettings = {
            pathname: location.pathname,
            price: price,
            listingId: content.section.listingId,
            maxGuest: content.section.accommodates,
            onSearchClick: this.onSearchClick,
            onInquiryClick: this.onInquiryClick,
            checkin: checkinState,
            checkout: checkoutState,
            guests: guestState,
            defaultGuest: 2,
            pageType: 'Listing',
            maxNights: content.section.terms.length > 0 ? content.section.terms[0].maxNights + 1 : 1
        }
        
        if (scroll.Y > scrollYIndex) {
            isStickyHeader = true;
            mainContainerStyle = {
                top: scrollYIndex
            };
            classNames = "listing-main-container__scroll";
        }
        else {
            mainContainerStyle = {
                zIndex: 1
            };
        }

        seo.customLdScript = [];
        seo.customLdScript.push(schemaGenerator.generateHotelSchema(content.section));
        seo.customLdScript.push(schemaGenerator.generateAggregateRatingSchema(content.section));

        let floorPlan;
        let suggestedListingMedia = null;

        let bannerPicturePath = content.section.imageBanner ? content.section.imageBanner.url.split("/") : content.section.pictures[0].url.split("/");
        let bannerMediaQuery = mediaCollection.find(x => x.fluid.originalName === bannerPicturePath[bannerPicturePath.length - 1]);
        
        if (content.section.floorPlan) {
            let floorPlanImgPath = content.section.floorPlan.url.split("/");
            let floorPlanImgName = floorPlanImgPath[floorPlanImgPath.length - 1];
            floorPlan = mediaCollection.find(x => x.fluid.originalName === floorPlanImgName);
        }

        if (content.section.suggestedListing.listingId) {
            let suggestedListingMediaPath = content.section.suggestedListing.pictures[0].url.split('/');
            suggestedListingMedia = mediaCollection.find(x => suggestedListingMediaPath[suggestedListingMediaPath.length - 1].includes(x.fluid.originalName));
        }

        let panoramicImagePath = layout.footer.panoramicImage.url.split('/');
        let homePageLogoImagePath = layout.homePageLogo.url.split('/');
        let panoramicMediaQuery = mediaCollection.find(x => panoramicImagePath[panoramicImagePath.length - 1] === x.fluid.originalName);
        let homPageLogoQuery = mediaCollection.find(x => homePageLogoImagePath[homePageLogoImagePath.length - 1] === x.fluid.originalName);

        if (layout.footer) {
            layout.footer.panoramicImageQuery = panoramicMediaQuery;
            layout.footer.footerImageQuery = homPageLogoQuery;
        }

        if (layout.header) {
            layout.header.headerImageQuery = homPageLogoQuery;
            layout.header.bgBanner = bannerMediaQuery?.fluid?.src;
        }

        if(bannerMediaQuery != null) {
            content.section.imageListing = [];
            content.section.imageListing.push(bannerMediaQuery.fluid.src);
        }

        seo.customLdScript = [];
        seo.customLdScript.push(schemaGenerator.generateHotelSchema(content.section));

        setTimeout(() => {
            this.computeBodyHeight()
        },100);

        return (
            <ThemeProvider theme={theme}>
                <ThemeContext.Consumer>
                    {
                        value =>
                        <Layout header={layout.header} footer={layout.footer} hideFooter={true} favicon={layout.favicon} searchBar={searchBarSettings} seo={seo} 
                            defaultHeaderTextToBlack={false} showHeaderMark={false} isStickyHeader={isStickyHeader} scrollYIndex={scrollYIndex} path={url} delayHeaderRender={true}>
                            <div className="listing-banner__header-content">
                                <div className="listing-banner__overlay">
                                    {/* Overlay */}
                                </div>
                                <div className="layout-column layout--centered listing-banner__content">
                                    {/* Content */}
                                    
                                    <div className="listing-banner__location">
                                        <ul className="concat-comma">
                                            <li>{content.section.address[0].city}</li>
                                            <li>{content.section.address[0].country}</li>
                                        </ul>
                                    </div>
                                    <div className="listing-banner__title">
                                        <h1 dangerouslySetInnerHTML={{ __html: content.section.title}}></h1>
                                    </div>
                                    <div className="listing-banner__inclusions">
                                        <ul className="text-center">
                                            <li>
                                                <img src={iconGuests} alt="guest" />
                                                <span>{content.section.accommodates} guests</span>
                                            </li>
                                            <li>
                                                <img src={iconBedrooms} alt="bedroom" />
                                                <span>{content.section.bedrooms} {content.section.bedrooms > 1 ? 'bedrooms' : 'bedroom'}</span>
                                            </li>
                                            <li>
                                                <img src={iconBeds} alt="bed" />
                                                <span>{content.section.beds} {content.section.beds > 1 ? 'beds' : 'bed'}</span>
                                            </li>
                                            <li>
                                                <img src={iconBathrooms} alt="bathroom" />
                                                <span>{content.section.bathrooms} {content.section.bathrooms > 1 ? 'bathrooms' : 'bathroom'}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="listing-banner__summary" dangerouslySetInnerHTML={{__html: content.section.publicDescription[0].summary}} />
                                </div>
                            </div>
                            <div className={`listing-main-container ${classNames}`} style={mainContainerStyle}>
                                <div className="listing-banner__header">
                                    {/* <BackgroundImage className="listing-banner__header-img"
                                        Tag="div"
                                        fluid={bannerMediaQuery.fluid}
                                        id={bannerPicturePath[bannerPicturePath.length - 2]}
                                        style={{
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'cover',
                                        }} /> */}
                                    <div className="listing-banner__header-img lazyload"
                                     data-bg={bannerMediaQuery?.fluid?.src}
                                     style={{
                                        // backgroundImage: `url(${bannerMediaQuery.fluid.src})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'bottom'
                                    }}>
                                    </div>
                                </div>
                                {
                                    loadTabs &&
                                    <ListingTabs amenities={content.section.amenities} address={content.section.address[0]} onChangeAmenityHeight={this.computeBodyHeight()}
                                    floorPlan={floorPlan} showAmenityButton={content.section.displayAmenitiesToggleButton} />
                                }
                                {pageSections}
                                {
                                    content.section.airbnbReviewId &&
                                    <div className={`elfsight-app-${content.section.airbnbReviewId} listing-review-widget`}></div>
                                }
                                {
                                    content.section.suggestedListing.listingId &&
                                    <div className="listing-suggested">
                                        {/* <BackgroundImage className="listing-suggested__bg-image"
                                        Tag="div"
                                        fluid={suggestedListingMedia.fluid}
                                        id={suggestedListingMedia[suggestedListingMedia.length - 2]}
                                        style={{
                                            backgroundSize: 'cover',
                                            backgroundRepeat: 'no-repeat'
                                        }}>
                                                <div className="listing-banner__overlay layout-column flex layout--centered">
                                                    <div className="listing-suggested__location">{content.section.suggestedListing.city + ', ' + content.section.suggestedListing.country}</div>
                                                    <div className="listing-suggested__title">{content.section.suggestedListing.title}</div>
                                                    <div className="listing-suggested__link">
                                                        <Link className={`btn ${value.buttonLight}`} to={content.section.suggestedListing.url}>View this condo</Link>
                                                    </div>
                                                </div>
                                        </BackgroundImage> */}
                                        <div className='listing-suggested__bg-image blurred-img' style={{
                                            backgroundImage: `url(${suggestedListingMedia.fluid.base64})`,
                                            backgroundPosition: 'bottom',
                                            position: 'absolute'
                                        }}>
                                            <img loading='lazy' src={suggestedListingMedia.fluid.src} alt={content.section.suggestedListing.title} />
                                            <div className="listing-banner__overlay layout-column flex layout--centered">
                                                <div className="listing-suggested__location">{content.section.suggestedListing.city + ', ' + content.section.suggestedListing.country}</div>
                                                <div className="listing-suggested__title">{content.section.suggestedListing.title}</div>
                                                <div className="listing-suggested__link">
                                                    <Link className={`btn ${value.buttonLight}`} to={content.section.suggestedListing.url}>View this condo</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <Footer footer={layout.footer}/>
                            </div>
                        </Layout>
                    }
                </ThemeContext.Consumer>
            </ThemeProvider>
        )
    }

    loadScript = () => {
        let scripts = document.querySelectorAll('script');
        let script = document.createElement('script');
        script.src = 'https://apps.elfsight.com/p/platform.js';
        script.defer = true;

        if (scripts.length > 0) {
            let hasAirbnbScript = false;
            scripts.forEach(element =>  {
                if (element.src === 'https://apps.elfsight.com/p/platform.js') {
                    hasAirbnbScript = true;
                }
            });

            if (!hasAirbnbScript) {
                document.body.appendChild(script);
            }
        }
    }
}

// export default Listing;
export default props => (
    <StaticQuery query={graphql`
        query lmediaCollection {
            allImageSharp {
                nodes {
                    fluid(maxWidth: 1920, srcSetBreakpoints: [600,900,1920]) {
                        ...GatsbyImageSharpFluid,
                        originalName,
                        presentationWidth
                    },
                    thumbnail:fixed(width: 100, height: 100) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `} 
    render={data => <Listing mediaCollection={data.allImageSharp.nodes} {...props}/>}/>
)